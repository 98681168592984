import React, { useEffect, useState } from 'react'
import HeroWidget from '../src/HeroWidget'
import { Boards } from "../src/BoardList"
import MetaTags  from "../src/MetaTags"
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { listBoards } from '../src/Firebase'
import LayoutFirebase from "../src/LayoutFirebase"

import { Button } from "../src/LoadingButton"
import Link from "next/link"
import FeaturesWidget from '../src/FeaturesWidget'

const Index = () => {
    const { t } = useTranslation()

    return (
      <>
        <MetaTags
          title="Bank Statement Converter - Generate Excel from PDFs"
          description="Easily convert your bank statements into Excel formats. Perfect for personal finance management and analysis."
          image="/assets/Bank-statement-converter.jpeg"
          video="/assets/Bank-statement-converter.mp4"
          videoWidth="1920"
          videoHeight="1080"
        />
        <LayoutFirebase>
          <HeroWidget />
          <FeaturesWidget />
        </LayoutFirebase>
      </>
    )}
export default Index

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
    revalidate: 3600 //hourly
  }
}
