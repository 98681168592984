import React, { useEffect, useContext, lazy, Suspense } from "react"

import { useTranslation } from "next-i18next"
import { Lock } from "@styled-icons/boxicons-solid/Lock"
import { Button } from "./LoadingButton.jsx"

const FeaturesWidget = props => {
  const { t } = useTranslation()

  return (
    <div>
      <section className="relative py-20 pb-36 bg-gray-50">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-50 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="flex flex-wrap-reverse md:flex-wrap md:flex-row-reverse">
            <div className="w-full md:w-6/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
              <img
                  className="rounded-lg shadow-xl object-contain"
                  src="assets/pdf-to-excel-secure.jpeg"
                  alt="Secure PDF to Excel Conversion"
                />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <div className="text-blue-900 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-200">
                  <Lock className="w-7 h-7" />
                </div>
                <h3 className="font-serif text-3xl font-semibold">
                  Simplify and Secure Your Finances.
                </h3>
                <p className="mt-4 mb-8 text-lg leading-relaxed text-gray-600">
                Transform your bank statements into organized Excel sheets with a single click, while ensuring your financial data remains secure with advanced encryption and privacy safeguards.
                </p>
                <Button href='/#' className='mt-8' highlight>📊 Start Converting</Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pb-24">
      <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        </section>
    </div>
  )
}

export default FeaturesWidget
