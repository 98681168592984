import React, {
  useEffect,
  useContext,
  lazy,
  Suspense,
  useState,
  Fragment,
} from "react";

import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Table } from "@styled-icons/bootstrap/Table";
import Link from "next/link";
import { FirebaseContext } from "./Firebase";
import Loading from "./Loading";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { Button } from "./LoadingButton";
import { FileExcel } from "@styled-icons/remix-line/FileExcel";

const HeroWidget = (props) => {
  const router = useRouter();
  const fb = useContext(FirebaseContext);
  const { user } = fb;
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [excelBlobUrl, setExcelBlobUrl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const userIsLoggedIn = user && !user.isAnonymous;
  const [error, setError] = useState(false);

  const handleChange = (setFunction, value) => {
    if (!userIsLoggedIn) {
      setIsDialogOpen(true);
      return;
    }
    setFunction(value);
  };

  const onCreate = () => {
    router.push(`/new-board`);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file) => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    if (file.type !== "application/pdf") {
      alert("Please select a PDF file.");
      return;
    }

    setIsLoading(true); // Start loading

    const reader = new FileReader();
    reader.onload = async (e) => {
      const base64 = e.target.result.split(",")[1]; // Remove base64 prefix

      await import("firebase/functions");
      const processPDF = fb.app.functions().httpsCallable("processPDF");

      try {
        const result = await processPDF({ pdf: base64 });
        console.log(result.data);

        // Assuming result.data contains the excelBase64
        const excelBase64 = result.data.excel;

        const byteCharacters = atob(excelBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const excelBlob = new Blob([byteArray], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(excelBlob);
        setExcelBlobUrl(url);

        setExcelBlobUrl(url);
      } catch (error) {
        console.error("Error uploading file:", error);
        setError(error);
      } finally {
        setIsLoading(false); // End loading
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="w-full bg-gray-50 md:pt-20 pb-32 md:pb-40">
        <div className="lg:flex items-center h-full xl:max-w-screen-2xl xl:mx-auto">
          <div className="w-full lg:w-3/5 xl:w-2/5 justify-center mx-auto max-w-screen-xl pt-16 px-8 lg:pl-20 py-10">
            <h1 className="text-4xl font-serif md:text-5xl lg:text-6xl text-blue-800 font-bold mb-8">
              Convert Bank Statements into Excel
            </h1>
            <p className="text-lg leading-relaxed text-gray-800 opacity-80 pr-10 md:pr-20 mb-8">
              Streamline your finance management with our easy-to-use bank
              statement converter. Generate Excel files from your PDF statements
              in seconds.
            </p>
            {isLoading && <Loading />}
            {!isLoading && (
              <div
                className={`h-44 flex items-center justify-center p-5 my-5 bg-blue-100 border-2 text-blue-800 rounded-xl focus:outline-none hover:opacity-70 ${
                  isDragging
                    ? "animate-pulse border-solid border-blue-800"
                    : "border-dashed border-blue-300"
                }`}
                onClick={() => document.getElementById("fileInput").click()}
                onDrop={(e) => handleChange(handleFileDrop, e)}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => {
                  e.preventDefault();
                  setIsDragging(true);
                }}
                onDragLeave={() => setIsDragging(false)}
                onDragEnd={() => setIsDragging(false)}
              >
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={(e) => handleChange(handleFileChange, e)}
                  accept="application/pdf"
                />
                <p className="text-lg leading-relaxed text-gray-800">
                  Drop your PDF here or click to convert into Excel.
                </p>
              </div>
            )}
            {excelBlobUrl && (
              <a
                href={excelBlobUrl}
                download="BankStatement.xlsx"
                className="inline-flex items-center justify-center text-lg my-4 ml-2 px-6 py-3 rounded-full focus:outline-none disabled:opacity-50 text-blue-800 bg-blue-50 hover:bg-blue-100 hover:text-blue-800 outline"
              >
                <FileExcel className="h-6 w-6 mr-2" />
                Download Bank Statement Excel
              </a>
            )}
            <div>
              {error?.code == "resource-exhausted" ? (
                <div className="flex flex-col md:flex-row justify-center items-center gap-3">
                  Please upgrade your plan to use the converter. 🫣
                  <Button href="/pricing" highlight>
                    {t("plans.upgrade")}
                  </Button>
                </div>
              ) : (
                error && <>Oops, the Excel generation failed.</>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/5 xl:w-3/5 justify-center lg:flex lg:flex-col px-8 lg:px-10 xl:px-20 py-8 lg:pr-20 xl:py-10">
            <div>
              <video
                className="rounded-lg shadow-xl bg-white"
                autoPlay
                loop
                muted
                playsInline
                poster="/assets/Bank-statement-converter.jpeg"
                height="720"
                width="1280"
              >
                <source
                  src="/assets/Bank-statement-converter.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
      <section className="relative py-18">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>

      <section className="relative py-20 md:pb-40">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap-reverse md:flex-wrap">
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
              <img
                className="rounded-lg shadow-xl"
                src="/assets/Smart-Bank-Statement-Converter.jpg"
                alt="Smart Bank Statement Converter"
              />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <div className="text-blue-900 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-200">
                  <Table className="w-7 h-7" />
                </div>
                <h3 className="font-serif text-3xl font-semibold">
                  Effortlessly Organize Your Finances.
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                BankPDFtoExcel leverages the latest AI technology to simplify your financial management.
                </p>
                <ul className="list-none mt-6 mb-8">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3"></span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                        Automated Bank Statement Conversion: Transform your bank statements into organized Excel sheets with a single click.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                          {/* <i className="fab fa-html5"></i> */}
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                        Smart Categorization: Advanced AI sorts transactions into categories, making it easy to track spending and manage budgets.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <a
                href='/#'
                data-testid="add-board"
                className="bg-blue-900 hover:bg-blue-700 py-2 px-4 text-white rounded-full focus:outline-none"
              >
                Convert Your Bank Statement
              </a>
            </div>
          </div>
        </div>
      </section>
      <Transition.Root show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={isDialogOpen}
          onClose={setIsDialogOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Subscription Required
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Please subscribe first to use the Bank Statement
                        Converter.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => setIsDialogOpen(false)}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                  <button
                    onClick={() => (window.location.href = "/pricing")}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Go to Pricing
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default HeroWidget;
