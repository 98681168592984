import React from "react"
import FirebaseProvider from "./Firebase"
import NavBar from "./NavBar"
import Content from "./ContentWrapper"

import Footer from "./Footer"

const LayoutFirebaseStream = ({ children }) => {
  return (
    <>
      <FirebaseProvider>
        <NavBar />
        <Content>{children}</Content>
        <Footer />
      </FirebaseProvider>
    </>
  )
}
export default LayoutFirebaseStream
